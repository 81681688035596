import logo from './logo.svg';
import './App.css';
import FirstScreen from './screens/FirstScreen';
import SecondScreen from './screens/SecondScreen';
import PriceList from './components/mehiron/Mehiron';
import ThirdScreen from './screens/ThirdScreen';
import ForthScreen from './screens/ForthScreen';
import ContactScreen from './screens/ContactScreen';
import ByMe from "./components/ByMe/ByMe"
function App() {
  return <>
  <FirstScreen/>
  <SecondScreen/>
 <ThirdScreen/>
 <ForthScreen/>
 <ContactScreen/>

  </>
}

export default App;
